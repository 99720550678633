export const es = {
  lang: "es",
  messages: {
    "Create your {{appName}} account": "Crea tu cuenta de {{appName}}",
    "Text too small.": "Texto demasiado pequeño.",
    "Invalid text.": "Texto no válido.",
    "The emails don't match": "Los correos electrónicos no coinciden",
    "Invalid email address": "Correo electrónico no válido",
    "Email address too small": "Correo electrónico demasiado corto",
    "The passwords don't match": "Las contraseñas no coinciden",
    "The password must contain 1 number 1 lower case letter and 1 upper case leter (may have special chars, spaces not allowed)":
      "La contraseña debe contener 1 número, 1 letra minúscula y 1 letra mayúscula (puede tener caracteres especiales, no se permiten espacios)",
    "Password must be at least 8 characters long.": "La contraseña debe tener al menos 8 caracteres.",
    "File must be type .pdf, .jpeg, .jpg or .png.": "El archivo debe ser de tipo .pdf, .jpeg, .jpg o .png.",
    "File size must be less than 5MB.": "El tamaño del archivo debe ser menor de 5MB.",
    "Upload file": "Subir archivo",
    "The username must only contain alphanumeric characters.": "El nombre de usuario solo puede contener caracteres alfanuméricos",
    "Username must have at least 4 characters.": "El nombre de usuario debe tener al menos 4 caracteres",
    Username: "Nombre de usuario",
    Password: "Contraseña",
    "New Password": "Nueva contraseña",
    "Reset password": "Restablecer contraseña",
    "Your account has been created successfully!": "¡Su cuenta ha sido creada con éxito!",
    "Your email has been verified, enter the platform by clicking the button below!": "Su correo ha sido verificado, ingrese a la plataforma haciendo clic en el botón a continuación",
    "The username is already taken, please choose another.": "El nombre de usuario ya está en uso, por favor elija otro.",
    "You will receive an email as soon as the account becomes active.":
      "Recibirá un correo electrónico tan pronto como la cuenta esté activa.",
    SEND: "ENVIAR",
    "Finish creating your {{appName}} account": "Finalice la creación de su cuenta de {{appName}}",
    "Thank you for confirming your email address.": "Gracias por confirmar su dirección de correo electrónico.",
    "Please define your username and your identity verification.":
      "Por favor defina su nombre de usuario y verificación de identidad.",
    "Please define your username.": "Por favor defina su nombre de usuario.",
    "<strong>Case-sensitive username.</strong><p>If there is already a username equal to the one chosen we will add a number at the end.</p><p>Example: username1</p>":
      "<strong>Nombre de usuario sensible a mayúsculas.</strong><p>Si ya existe un nombre de usuario igual al elegido, añadiremos un número al final.</p><p>Ejemplo: username1</p>",
    "<strong>The password must be at least 8 characters long, have 1 number, 1 lower case letter and 1 uppercase letter.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>":
      "<strong>La contraseña debe tener al menos 8 caracteres, incluir 1 número, 1 letra minúscula y 1 letra mayúscula.</strong><p>Puede incluir caracteres especiales.</p><p>No se permiten espacios.</p>",
    "{{appName}} account": "Cuenta de {{appName}}",
    Identification: "Identificación",
    "Identification document - front": "Documento de identificación - frente",
    "Identification document - backside (if not already included on the file above)":
      "Documento de identificación - reverso (si no está incluido en el documento de arriba)",
    Address: "Dirección",
    "Post code": "Código postal",
    City: "Ciudad",
    "Proof of address": "Comprobante de domicilio",
    "<p>Dated less than 3 months.</p>": "<p>Con fecha de menos de 3 meses.</p>",
    "Registration successful!": "¡Registro exitoso!",
    "Please check your email to confirm your identity.": "Por favor revise su correo para confirmar su identidad",
    '(Please check in the "Spam" box)': '(Revise también la carpeta de "Spam")',
    "First Name": "Nombre",
    "Last Name": "Apellido",
    Country: "País",
    Currency: "Moneda",
    "Expected monthly volume": "Volumen mensual esperado",
    "Interface page for register on <a href='{{siteLoginUrl}}' target='_blank'>{{siteLogin}}</a> betting platform":
      "Página de registro en la plataforma de apuestas <a href='{{siteLoginUrl}}' target='_blank'>{{siteLogin}}</a>.",
    "This is the page for making an account on {{appName}}, if you have already done this step then go to <a href='{{siteLoginUrl}}' target='_blank'>{{siteLogin}}</a> or click 'Login' on <a href='{{logoLinkUrl}}' target='_blank'>{{logoLink}}</a>.":
      "Esta es la página para crear una cuenta en {{appName}}, si ya completó este paso, vaya a <a href='{{siteLoginUrl}}' target='_blank'>{{siteLogin}}</a> o haga clic en 'Entrar' en <a href='{{logoLinkUrl}}' target='_blank'>{{logoLink}}</a>.",
    "It only takes a minute": "Solo toma un minuto",
    "We detected that you already have a {{appName}} account and we now resend an email with your details.":
      "Detectamos que ya tiene una cuenta en {{appName}} y reenviamos ahora un correo con sus datos.",
    'Please check your email to confirm your identity (Please check in the "Spam" box)':
      'Por favor revise su correo (Revise también en la carpeta de "Spam").',
    "Password updated successfully!": "¡Contraseña actualizada con éxito!",
    "Login at <a href='{{siteLoginUrl}}'>{{siteLogin}}</a> and start betting!":
      "Inicie sesión en <a href='{{siteLoginUrl}}'>{{siteLogin}}</a> y comience a apostar!",
    "This username does not match the current password reset token.":
      "Este nombre de usuario no coincide con el token de restablecimiento de contraseña actual.",
    "Welcome to {{domainName}} betting platform. The most respected Asian bookmakers, where winners are welcome.":
      "Bienvenido a la plataforma de apuestas {{domainName}}. Los corredores de apuestas asiáticos más respetados, donde los ganadores son bienvenidos.",
    "Register a new account on {{name}}": "Registrar una nueva cuenta en {{name}}",
    "id-card-name-EN": "Número de pasaporte o ID local",
    CPF: "CPF",
    "Phone Number": "Número de teléfono",
    "Number between 5 and 15 digits, can start with '+'": "Número entre 5 y 15 dígitos, puede comenzar con '+'",
    "Invalid Pattern": "Patrón no válido",
    "Invalid CPF number": "Número de CPF no válido",
    "You must be 18 or older": "Debe tener 18 años o más",
    "Birthdate": "Fecha de nacimiento",
    "Invalid date": "Fecha no válida",
    "Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.":
      "Nota: en este documento deben ser visibles el nombre completo, foto, fecha de nacimiento y número de identificación o pasaporte.",
    "Accepted: National Id or Drivers license.<br>Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.":
      "Aceptado: identificación nacional o licencia de conducir.<br>Nota: en este documento deben ser visibles el nombre completo, foto, fecha de nacimiento y número de identificación o pasaporte.",
    "Confirm Password": "Confirmar contraseña",
    "Confirm Email": "Confirmar correo",
    "(DD-MM-YYYY)": "(DD-MM-AAAA)",
    "Day": "Día",
    "Month": "Mes",
    "Year": "Año",
    "I confirm I have read and I agree to the <a id='{{TermsAndConditions}}' href='#'>Terms and Conditions</a> and <a id='{{PrivacyPolicy}}' href='#'>Privacy Policy</a> and confirm I am 18 years of age or over.":
      "Confirmo que he leído y estoy de acuerdo con los <a id='{{TermsAndConditions}}' href='#'>Términos y Condiciones</a> y la <a id='{{PrivacyPolicy}}' href='#'>Política de Privacidad</a> y confirmo que tengo 18 años o más.",
    "You need to confirm Terms and Conditions and Privacy Policy": "Debe confirmar los Términos y Condiciones y la Política de Privacidad",
    "Terms And Conditions": "Términos y Condiciones",
    "Value already in use": "Valor ya está en uso"
  },
};
