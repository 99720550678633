import { SiteSettings } from "./settings-class";

export const settings: SiteSettings = {
  siteName: "Vertex",
  domainName: "Vertexb88.com",
  logoLink: "",
  siteLogin: "vertexb88.com",
  paymentsApiUrl: "https://register.vertexb88.com/api",
  email: "support@vertexb88.com",
  folderImgs: "vertexb88-imgs",
  registerAskVolume: false,
  currenciesList: ["USD"],
  defaultLang: 'gb',
  allowedLanguagesList: {en: 'gb', pt: 'br', es: 'es'},
  styles: {
    headerBackgroundColor: "#222",
    headerTextColor: "#fff"
  }
};
