import { SiteSettings } from "./settings-class";

export const settings: SiteSettings = {
  siteName: "BettingBeast",
  domainName: "bettingbeast.com",
  logoLink: "bettingbeast.com",
  siteLogin: "play.bettingbeast.com",
  paymentsApiUrl: "https://register.bettingbeast.com/api",
  email: "support@bettingbeast.com",
  folderImgs: "bettingbeast-imgs",
  registerAskVolume: false,
  currenciesList: ["USD"], // coming from the api, /agent-info
  defaultLang: 'gb',
  allowedLanguagesList: {en: 'gb', pt: 'br', es: 'es'},
  styles: {
    headerBackgroundColor: "#01000A",
    headerTextColor: "#ffffff",
    headerBackgroundImage: "/img/bettingbeast-imgs/background-image.png"
  },

};
